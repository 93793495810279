<template>
  <div>
    <el-dialog title="不通过原因"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="60px">
        <el-form-item label="原因：">
          <el-input type="textarea"
                    :rows="5"
                    v-model="form.comment"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
    }
  },
  components: {
  },
  mounted () {

  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      this.$http({
        url: '/api/v1/error/passed',
        method: 'post',
        data: this.form
      }).then(res => {
        this.handleClose()
        this.$message.success('成功')
        this.$parent.getParentList()
      })
    },


  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.el-input {
  width: 100px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>